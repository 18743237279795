import { create, merge } from 'lodash';
import { dispatch } from '../store';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
// types
import { School } from '../../@types/school';
import { DammaUser } from '../../@types/damma-user';
import { DammaSerial, NewPromoForm, GeneratedPromocode } from '../../@types/subscription';
// ----------------------------------------------------------------------

type SupervisorState = {
  isLoading: boolean;
  isFetchSchoolsFinished: boolean;
  isFetchUsersFinished: boolean;
  isFetchPromosFinished: boolean;
  isGeneratePromosFinished: boolean;
  isCreateUserFinished: boolean;
  error: boolean;
  userList: DammaUser[];
  schoolList: School[];
  promocodeList: DammaSerial[];
  generatedPromos: GeneratedPromocode[];
};

const initialState: SupervisorState = {
  isLoading: false,
  isFetchSchoolsFinished: false,
  isFetchUsersFinished: false,
  isFetchPromosFinished: false,
  isGeneratePromosFinished: false,
  isCreateUserFinished: false,
  error: false,
  userList: [],
  schoolList: [],
  promocodeList: [],
  generatedPromos: []
};

const slice = createSlice({
  name: 'supervisor',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.isFetchUsersFinished = true;
      state.userList = action.payload;
    },

    // GET SCHOOLS
    getSchoolsSuccess(state, action) {
      state.isLoading = false;
      state.isFetchSchoolsFinished = true;
      state.schoolList = action.payload;
    },

    // GET PROMOS
    getPromosSuccess(state, action) {
      state.isLoading = false;
      state.isFetchPromosFinished = true;
      state.promocodeList = action.payload;
    },

    // Create School
    createSchoolsSuccess(state, action) {
      state.isLoading = false;
      state.isFetchSchoolsFinished = true;
      state.schoolList.push(action.payload);
    },

    startGenerating(state) {
      state.isGeneratePromosFinished = false;
    },

    // Generate Promocodes
    generatePromocodesSuccess(state, action) {
      state.isLoading = false;
      state.isGeneratePromosFinished = true;
      action.payload.map((o: GeneratedPromocode) => state.generatedPromos.push(o));
    },

    // Update Specific Promocode Info
    updatePromoSuccess(state, action) {
      state.isLoading = false;
      state.promocodeList = state.promocodeList.map((o: DammaSerial) => {
        if (o.id === action.payload.id) {
          return { ...o, ...action.payload };
        }
        return o;
      });
    },

    getSubscribeSuccess(state, action) {
      state.isLoading = false;
      state.userList = state.userList.map((u: DammaUser) => {
        if (u.id === action.payload.userID) {
          return merge(u, action.payload.data);
        }
        return u;
      });
    },

    // Create User
    createUserSuccess(state, action) {
      state.isLoading = false;
      const mappedUser: DammaUser = {
        UID: action.payload.UID,
        id: action.payload.UID,
        displayName: action.payload.displayName,
        email: action.payload.email,
        role: action.payload.role,
        gender: action.payload.gender,
        imageURL: action.payload.imageURL,
        createdAt: new Date().toISOString(),
        schoolID: action.payload.schoolID,
        serialID: '',
        isVerified: false,
        isSubscribed: false,
        school: {
          name: action.payload.schoolName,
          schoolID: action.payload.schoolID,
          nameEn: action.payload.SchoolNameEn,
          phoneNo: action.payload.schoolPhoneNo,
          country: action.payload.schoolCountry,
          email: action.payload.schoolEmail,
          maxStudentsNo: action.payload.schoolMaxStudentsNo,
          maxTeachersNo: action.payload.schoolMaxTeachersNo
        },
        subscription: {
          serialID: null,
          validUntil: null,
          subscriptionType: null,
          subscriptionValidUntil: null,
          dead: null,
          serialNo: null
        }
      };
      state.userList.push(mappedUser);
      // state.userList.push(action.payload);
      state.isCreateUserFinished = true;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

// create new user            ----------------------------------------------------------------------
export function registerUser(user: DammaUser) {
  dispatch(slice.actions.createUserSuccess(user));
}

// get all users with subscription status ----------------------------------------------------------------------
export function getUsers(userID: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/users`, {
        userID
      });
      const now = new Date();
      const rawUsers: DammaUser[] = response.data.records;
      const users = await rawUsers.map((user) => {
        const { subscription, displayName } = user;
        let tempUser = { ...user, isSubscribed: false };
        if (
          (subscription.validUntil && subscription.validUntil > now.toISOString().slice(0, 10)) ||
          (subscription.subscriptionValidUntil &&
            subscription.subscriptionValidUntil > now.toISOString().slice(0, 10))
        ) {
          if (subscription.validUntil)
            tempUser = {
              ...tempUser,
              isSubscribed: true,
              subscription: { ...subscription, subscriptionType: 'promo' }
            };
          else tempUser = { ...tempUser, isSubscribed: true };
        }
        if (
          !displayName ||
          typeof displayName === 'object' ||
          !displayName.replace(/\s/g, '').length
        )
          tempUser = { ...tempUser, isSubscribed: false, displayName: '-unKnown-' };
        return tempUser;
      });
      dispatch(slice.actions.getUsersSuccess(users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// get all users with subscription status for specific school ----------------------------------------------------------------------
export function getSchools(userID: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/schools`, {
        userID
      });
      const rawSchools: School[] = response.data;
      dispatch(slice.actions.getSchoolsSuccess(rawSchools));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// create new school            ----------------------------------------------------------------------
export function createSchool(userID: string, data: School) {
  return new Promise(async (resolve) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/school`, {
        userID,
        ...data
      });
      const school = { ...data, id: 0 };
      dispatch(slice.actions.createSchoolsSuccess(school));
      // resolve(school.schoolID);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  });
}

// get all promocodes with details and status ----------------------------------------------------------------------
export function getPromos(userID: string, schoolID: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/super/promos`, {
        userID,
        schoolID
      });
      const promos: DammaSerial[] = response.data.records;
      dispatch(slice.actions.getPromosSuccess(promos));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// generate new promocodes     ----------------------------------------------------------------------
export function newPromos(userID: string, data: NewPromoForm) {
  return new Promise(async (resolve) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startGenerating());
    try {
      const response = await axios.post(`/generatePromoCodes`, {
        userID,
        ...data
      });
      const promos: GeneratedPromocode[] = response.data;
      dispatch(slice.actions.generatePromocodesSuccess(promos));
      resolve(promos);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  });
}

// update Promocode Information     ----------------------------------------------------------------------
export function updatePromoInfo(userID: string, data: any) {
  return new Promise(async (resolve) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/promoInfo`, {
        userID,
        ...data
      });
      if (response.data) dispatch(slice.actions.updatePromoSuccess(response.data));
      resolve(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  });
}

// update Users List           ----------------------------------------------------------------------
export function setSubscription(UID: string, promo: string) {
  return new Promise(async (resolve) => {
    dispatch(slice.actions.startLoading());
    try {
      const date = new Date();
      const response = await axios.post('/newSubscription', { UID, promoCode: promo });
      if (
        response.data.hasOwnProperty('validUntil') &&
        response.data.validUntil > date.toISOString().slice(0, 10)
      ) {
        let data = response.data;
        dispatch(
          slice.actions.getSubscribeSuccess({
            data: {
              isSubscribed: true,
              validUntil: new Date(data.validUntil),
              schoolID: data.schoolID,
              school: {
                schoolName: data.schoolName
              },
              subscription: {
                validUntil: response.data.validUntil,
                subscriptionType: 'promo',
                serialNo: promo
              },
              role: data.role
            },
            userID: UID
          })
        );
        dispatch(slice.actions.hasError(0));
        resolve(0);
      } else {
        dispatch(slice.actions.hasError(1));
        resolve(1);
      }
    } catch (error: any) {
      if (error.message === 'Promo code is already used by another user') {
        dispatch(slice.actions.hasError(3));
        resolve(3);
      } else {
        dispatch(slice.actions.hasError(2));
        resolve(2);
      }
    }
  });
}
